// Here you can add other styles

.accordion-button::after {
    background-image: initial;
  }
  
.accordion-button:not(.collapsed)::after {
  background-image: initial;
}

.viewport {
  width: 150px;
  overflow-y: auto;
}
.item {
  height: 20px;
}